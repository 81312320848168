import React from 'react';
import { Cell, Grid } from 'react-foundation';
import useIntl from '../../hooks/useIntl';
import styles from './FDAStatement.module.scss';
import messages from './messages';

const FDAStatement = () => {
  const { formatMessage } = useIntl();
  return (
    <Grid className={styles.container}>
      <Cell small={12}>
        <div className={styles.text}>{formatMessage(messages.statement)}</div>
      </Cell>
    </Grid>
  );
};

export default FDAStatement;
