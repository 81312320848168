export const AVAILABLE_CATEGORY_THEMES = [
  'yellow',
  'green',
  'orange',
  'blue',
  'purple',
];
export const STAMP_URL = 'https://www.stamps.com/shipstatus/?confirmation=';

export const SCHEDULE_OPTIONS = {
  now: { label: 'Now', value: 'Now' },
  scheduleDate: { label: 'Schedule Date', value: 'Schedule Date' },
  onRegistration: { label: 'On Registration', value: 'On Registration' },
  intakeForNewClients: {
    label: 'Intake for New Clients',
    value: 'Intake for New Clients',
  },
};

export const MANAGE_FORM_TABS = {
  PROTOCOL_TEMPLATES: 'Protocol Templates',
  ACTIVE_PROTOCOLS: 'Active Protocols',
  COMPLETED_PROTOCOLS: 'Completed Protocols',
};

export const APPOINTMENT_STATUSES = {
  PAID: 'paid',
  UNPAID: 'unpaid',
  CANCELED: 'canceled',
};

export const FORM_AND_ASSESSMENT_TABS = {
  NEW: 'New',
  COMPLETED: 'Completed',
};

export const USER_TYPES = {
  ADMIN: 'admin',
  PATIENT: 'patient',
  DOCTOR: 'doctor',
  CLINICIAN: 'clinician', // TODO why do we need this one
  RESEARCHER: 'researcher',
};

export const CALENDAR_TYPES = {
  NYLAS: 'NYLAS',
  CALENDLY: 'CALENDLY',
};

export const APPOINTEMNT_URLS = {
  DOCTOR: '/doctor/appointments',
  PATIENT: '/my-account/appointments',
};

export const APPOINTEMNT_STATUS = {
  UNPAID: 'unpaid',
  PAID: 'paid',
  CANCELED: 'canceled',
};

export const FORM = {
  STATUS: {
    ASSIGNED: 'assigned',
    COMPLETED: 'completed',
  },
  TYPE: {
    ASSESSMENT: 'assessment',
    INTAKE: 'intake',
    CONSENT: 'consent',
  },
  INTAKE_TYPE: {
    PATIENT_INFO: 'patientDetailsIntake',
    BPS: 'bpsIntake',
    MOOSE: 'moosIntake',
    SOLSTICE: 'solsticeIntake',
    ANAHITA: 'anahitaIntake',
    WILD_HEART: 'wildHeartIntake',
  },
};

export const INTAKE_OPTIONS = [
  { label: 'BPS Intake', value: FORM.INTAKE_TYPE.BPS },
  { label: 'Patient Details Intake', value: FORM.INTAKE_TYPE.PATIENT_INFO },
  { label: 'Moos Intake', value: FORM.INTAKE_TYPE.MOOSE },
  { label: 'Solstice Intake', value: FORM.INTAKE_TYPE.SOLSTICE },
  { label: 'Anahita Anais Intake', value: FORM.INTAKE_TYPE.ANAHITA },
  { label: 'Wild Heart Intake', value: FORM.INTAKE_TYPE.WILD_HEART },
];

export const INIVITE_SOURCE = {
  INVITE_URL: 'invite_url',
};

export const SUPERBILL_REQUESTED = 'REQUESTED';

export const PATIENT_DETAILS_STATUS = {
  ACTIVE: 'ACTIVE',
  INACTIVE: 'INACTIVE',
};

export const ACCOUNT_TYPE = {
  MEMBER: 'Member',
  SOLO: 'Solo',
  NEWCLINIC: 'NewClinic',
  OWNER: 'Owner',
  ADMIN: 'admin',
};

export const INVITED_CLINICIAN_INVITED_STATUS = {
  PENDING: 'pending',
  COMPLETE: 'complete',
};

export const CLINICIAN_REGISTRATION_STATUS = {
  PENDING: 'pending',
  ENABLE: 'enable',
  DISABLE: 'disable',
};
