import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import { Button } from 'react-foundation';
import PropTypes from 'prop-types';
import styles from './SecondaryButton.module.scss';

const SecondaryButton = ({
  label,
  onClick,
  className,
  disabled,
  type,
  'data-testid': datatestid,
  ...props
}) => {
  const [isDisabled, setIsDisabled] = useState(false);
  useEffect(() => setIsDisabled(disabled), [disabled]);
  return (
    <Button
      type={type}
      className={classNames(styles.secondary, {
        [className]: className,
      })}
      onClick={async () => {
        setIsDisabled(true);
        onClick && (await onClick());
        setIsDisabled(false);
      }}
      data-testid={datatestid}
      disabled={isDisabled}
      {...props}
    >
      {label}
    </Button>
  );
};

SecondaryButton.propTypes = {
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  className: PropTypes.string,
  type: PropTypes.string,
};

export default SecondaryButton;
