import { defineMessages } from 'react-intl';

export default defineMessages({
  metaTitle: {
    id: 'pages.homePage.metaTitle',
    description: 'Meta title for home page',
    defaultMessage: 'vailvalleytech.co | EVE',
  },
  metaDescription: {
    id: 'pages.homePage.metaDescription',
    description: 'Meta description for home page',
    defaultMessage:
      'EVE is elevating human health and wellbeing. Discover your individualized wellness using the power of data and technology. Enjoy free shipping on every order.',
  },
  topTitle: {
    id: 'pages.homePage.topTitle',
    description: 'Title in top side of page',
    defaultMessage: 'modern science meets ancient wellness',
  },
  topSubtitle: {
    id: 'pages.homePage.topSubtitle',
    description: 'Subtitle in top side of page',
    defaultMessage:
      'Discover your individualized wellness using the power of data and technology.',
  },
  shippingBanner: {
    id: 'pages.homePage.shippingBanner',
    description: 'Shipping banner',
    defaultMessage: 'Free Shipping On All US Orders',
  },
  getStarted: {
    id: 'pages.homePage.surveyButton',
    description: 'Get started button label',
    defaultMessage: 'Get started',
  },
  learnMoreBtn: {
    id: 'pages.homePage.learnMoreBtn',
    description: 'Learn more button label',
    defaultMessage: 'Learn more',
  },
  getStartedSectionTitle: {
    id: 'pages.homePage.section.getStarted.title',
    description: 'Title for the get started section',
    defaultMessage: 'Plant-Powered AI',
  },
  getStartedSectionSubtitle: {
    id: 'pages.homePage.section.getStarted.subtitle',
    description: 'Subtitle for the get started section',
    defaultMessage: "We'll walk you through everything.",
  },
  getToKnowYouTitle: {
    id: 'pages.homePage.section.getStarted.getToKnowYou.title',
    description: 'Title for the get to know you section',
    defaultMessage: "let's get to know you",
  },
  getToKnowYouTextPart1: {
    id: 'pages.homePage.section.getStarted.getToKnowYou.textPart1',
    description: 'Text for the get to know you section',
    defaultMessage: 'We’re here to listen.',
  },
  getToKnowYouTextPart2: {
    id: 'pages.homePage.section.getStarted.getToKnowYou.textPart2',
    description: 'Text for the get to know you section',
    defaultMessage: 'Tell us who you are ',
  },
  getToKnowYouTextPart3: {
    id: 'pages.homePage.section.getStarted.getToKnowYou.textPart3',
    description: 'Text for the get to know you section',
    defaultMessage: 'and how you’re feeling.',
  },
  recommendationsTitle: {
    id: 'pages.homePage.section.getStarted.recommendations.title',
    description: 'Title for the recommendations section',
    defaultMessage: 'get your individualized recommendations',
  },
  recommendationsText: {
    id: 'pages.homePage.section.getStarted.recommendations.text',
    description: 'Text for the recommendations section',
    defaultMessage:
      'We’ll recommend premium, ORO Certified transformative health products targeted to meet your needs.',
  },
  feedbackTitle: {
    id: 'pages.homePage.section.getStarted.feedback.title',
    description: 'Title for the feedback section',
    defaultMessage: 'keep us in the loop',
  },
  feedbackText: {
    id: 'pages.homePage.section.getStarted.feedback.text',
    description: 'Text for the feedback section',
    defaultMessage:
      'Your feedback helps to continually tune and refine your transformative health recommendations.',
  },
  cbdSectionTitle: {
    id: 'pages.homePage.section.cbd.title',
    description: 'Title for the cbd section',
    defaultMessage: "let's talk CBD",
  },
  cbdLegalTitle: {
    id: 'pages.homePage.section.cbd.legal.title',
    description: 'Title for the cbd legal section',
    defaultMessage: 'it’s 100% legal federally',
  },
  cbdLegalText: {
    id: 'pages.homePage.section.cbd.legal.text',
    description: 'Text for the cbd legal section',
    defaultMessage:
      'Hemp-based CBD is considered a labeled dietary supplement, online and over-the-counter.',
  },
  effectiveTitle: {
    id: 'pages.homePage.section.cbd.effective.title',
    description: 'Title for the cbd effective section',
    defaultMessage:
      'plant-based wellness is effective & backed by clinical research',
  },
  effectiveText: {
    id: 'pages.homePage.section.cbd.effective.text',
    description: 'Text for the cbd effective section',
    defaultMessage:
      'CBD improves health and wellness by targeting the human endocannabinoid system (ECS) and regulating physiology, mood and everyday experience.',
  },
  marijuanaTitle: {
    id: 'pages.homePage.section.cbd.marijuana.title',
    description: 'Title for the cbd marijuana section',
    defaultMessage: 'it’s not marijuana, it won’t get you high',
  },
  marijuanaTitleMobilePart1: {
    id: 'pages.homePage.section.cbd.marijuana.title.mobile.part1',
    description: 'Title for the cbd marijuana section in mobile version part 1',
    defaultMessage: 'it’s not marijuana,',
  },
  marijuanaTitleMobilePart2: {
    id: 'pages.homePage.section.cbd.marijuana.title.mobile.part2',
    description: 'Title for the cbd marijuana section in mobile version part 2',
    defaultMessage: 'it won’t get you high',
  },
  marijuanaText: {
    id: 'pages.homePage.section.cbd.marijuana.text',
    description: 'Text for the cbd marijuana section',
    defaultMessage:
      'Our CBD products are hemp-derived and contain less than 0.3% THC, so you get the benefits without the buzz.',
  },
  innovationTitle: {
    id: 'pages.homePage.section.innovation.title',
    description: 'Title for the innovation section',
    defaultMessage: 'human-centered innovation',
  },
  innovationText: {
    id: 'pages.homePage.section.innovation.text',
    description: 'Text for the innovation section',
    defaultMessage:
      'Using customer feedback, data science and advanced machine learning, we deliver effective products and individualized recommendations validated by our community.',
  },
  collaborationTitle: {
    id: 'pages.homePage.section.collaboration.title',
    description: 'Title for the collaboration section',
    defaultMessage: 'we believe in the power of collaboration',
  },
  collaborationText: {
    id: 'pages.homePage.section.collaboration.text',
    description: 'Text for the collaboration section',
    defaultMessage:
      'Your health is our inspiration to do better. Join our community of learners, collaborating, communicating, cultivating a new path to better health and happiness.',
  },
  bestSellersTitle: {
    id: 'pages.homePage.section.bestSellers.title',
    description: 'Title for the best sellers section',
    defaultMessage: 'today’s best sellers',
  },
  bestSellersText: {
    id: 'pages.homePage.section.bestSellers.text',
    description: 'Text for the best sellers section',
    defaultMessage: 'Discover which products meet your individualized needs.',
  },
  shopAllBtn: {
    id: 'pages.homePage.section.bestSeller.shopAllBtn',
    description: 'Text for the shop all button',
    defaultMessage: 'Shop all products',
  },
  honestTitle: {
    id: 'pages.homePage.section.honest.title',
    description: 'Title for the honest section',
    defaultMessage: 'honest wellness',
  },
  honestText: {
    id: 'pages.homePage.section.honest.text',
    description: 'Text for the honest section',
    defaultMessage:
      'Any product we recommend undergoes ORO’s exacting and rigorous vetting and certification process to ensure safety, purity and efficacy.',
  },
  bannerHonestAlt: {
    id: 'pages.homePage.section.honest.bannerHonestAlt',
    description: 'Alt text for the banner honest image',
    defaultMessage: 'Banner Honest',
  },
  wellnessProductAlt: {
    id: 'pages.homePage.section.honest.wellnessProductAlt',
    description: 'Alt text for the wellness product image',
    defaultMessage: 'Wellness Product',
  },
  oroVerifiedAlt: {
    id: 'pages.homePage.section.honest.oroVerifiedAlt',
    description: 'Alt text for the oro verified image',
    defaultMessage: 'ORO Verified',
  },
});
