import { useContext } from 'react';
import { IntlContext } from '../../context/IntlContext';

// the intl hook
export default () => {
  const intl = useContext(IntlContext);
  return {
    formatMessage: intl.formatMessage,
    locale: intl.locale,
    formatDate: intl.formatDate,
    formatTime: intl.formatTime,
  };
};
