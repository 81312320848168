const hostname = window && window.location && window.location.hostname;

const square_application_id = () => {
  if (hostname.toLocaleLowerCase().includes('healthyyoungminds')) {
    return process.env.REACT_APP_SQUARE_APPLICATION_ID_YMHC;
  } else if (hostname.toLocaleLowerCase().includes('wellbeings')) {
    return process.env.REACT_APP_SQUARE_APPLICATION_ID_WB;
  } else if (hostname.toLocaleLowerCase().includes('psychedelicsupport')) {
    return process.env.REACT_APP_SQUARE_APPLICATION_ID_PS;
  } else if (hostname.toLocaleLowerCase().includes('medicinalmindfulness')) {
    return process.env.REACT_APP_SQUARE_APPLICATION_ID_MM;
  } else if (hostname.toLocaleLowerCase().includes('advancedwellness')) {
    return process.env.REACT_APP_SQUARE_APPLICATION_ID_AW;
  } else if (hostname.toLocaleLowerCase().includes('reichbach')) {
    return process.env.REACT_APP_SQUARE_APPLICATION_ID_RB;
  } else {
    return process.env.REACT_APP_SQUARE_APPLICATION_ID;
  }
};

export const hello_sign_client_id = () => {
  if (hostname.toLocaleLowerCase().includes('healthyyoungminds')) {
    return process.env.REACT_APP_HELLOSIGN_CLIENT_ID_YMHC;
  } else if (hostname.toLocaleLowerCase().includes('wellbeings')) {
    return process.env.REACT_APP_HELLOSIGN_CLIENT_ID_WB;
  } else if (hostname.toLocaleLowerCase().includes('psychedelicsupport')) {
    return process.env.REACT_APP_HELLOSIGN_CLIENT_ID_PS;
  } else if (hostname.toLocaleLowerCase().includes('medicinalmindfulness')) {
    return process.env.REACT_APP_HELLOSIGN_CLIENT_ID_MM;
  } else if (hostname.toLocaleLowerCase().includes('advancedwellness')) {
    return process.env.REACT_APP_HELLOSIGN_CLIENT_ID_AW;
  } else if (hostname.toLocaleLowerCase().includes('reichbach')) {
    return process.env.REACT_APP_HELLOSIGN_CLIENT_ID_RB;
  } else {
    return process.env.REACT_APP_HELLOSIGN_CLIENT_ID;
  }
};

export const okta_redirect_url = () => {
  if (hostname.toLocaleLowerCase().includes('healthyyoungminds')) {
    return process.env.REACT_APP_OKTA_REDIRECT_URL_YMHC;
  } else if (hostname.toLocaleLowerCase().includes('wellbeings')) {
    return process.env.REACT_APP_OKTA_REDIRECT_URL_WB;
  } else if (hostname.toLocaleLowerCase().includes('psychedelicsupport')) {
    return process.env.REACT_APP_OKTA_REDIRECT_URL_PS;
  } else if (hostname.toLocaleLowerCase().includes('medicinalmindfulness')) {
    return process.env.REACT_APP_OKTA_REDIRECT_URL_MM;
  } else if (hostname.toLocaleLowerCase().includes('advancedwellness')) {
    return process.env.REACT_APP_OKTA_REDIRECT_URL_AW;
  } else if (hostname.toLocaleLowerCase().includes('reichbach')) {
    return process.env.REACT_APP_OKTA_REDIRECT_URL_RB;
  } else {
    return process.env.REACT_APP_OKTA_REDIRECT_URL;
  }
};

export const SQUARE_APPLICATION_ID = square_application_id();

export const HELLO_SIGN_CLIENT_ID = hello_sign_client_id();

export const OKTA_REDIRECT_URL = okta_redirect_url();
