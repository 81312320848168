import React, { createContext } from 'react';
import { injectIntl } from 'react-intl';

const IntlContext = createContext({});

// turn the old context into the new context
const InjectIntlContext = injectIntl(({ intl, children }) => (
  <IntlContext.Provider value={intl}>{children}</IntlContext.Provider>
));

export { IntlContext, InjectIntlContext };
